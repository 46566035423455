/* eslint-disable prettier/prettier */
export default () => ({
  // auth
  id_token_expires_in: null,
  refresh_token: '',
  isAuthenticated: false,
  state: [],
  refreshingToken: false,
  token: '',
  userId: '',
  userRole: '',
  userName: '',
  menuLinks: [],
  collision: false,
  historyRoute: false,
  liveStreaming: false,
  historyVideo: false,
  processAppeal: false,
  submitAppeal: false,
  compareScore: false,
  isAdmin: false,

  firstPage: '',
  currentPath: '',

  drawer: null,
  toggleDrawer: false,
  locales: ['en', 'zh-Hant'], // 'zh-Hans' pending
  locale: 'en',
  company: '',
  companies: [],
  companyRole: '',
  camType: [],
  // companyId: 6,
  title: '',
  vehicleList: {
    items: [],
    status: {}
  },
  getVehicle: [],
  checkType: [],
  getPloyline: [],
  getMarker: [],
  getMobileLatLng: [],
  getCluster: [],

  profileViewMode:false,
  show100marks:false,
  warningFilter:[],

  // * show Idling
  showIdling: false,
});
