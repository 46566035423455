
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
import SettingApiService from '@/services/settings-service';
import { mapState } from 'vuex';
export default {
  props: {
    tableFlag: {
      type: String,
      default: '',
    }, // Filter if it is score label or count label or a video label for warnings, 's' for score, 't' for count, 'i' for video params

    // apiFlag: {
    //   type: String,
    //   default: '',
    // }, // 'a' for actual warning count, 'w' for warning count/100km, 's' for safety score, 'r' for score ranking.

    // The query to be called
    query: {
      type: Object,
      default: () => {},
    },
    // key: {
    //   type: String,
    //   default: '',
    // },
  },
  data: () => ({
    tabList: null,
    // tabs: ['Company', 'VehicleGroup', 'Vehicle', 'DriverGroup', 'Driver'],
    // Subset of tabs
    // drivergrouptabs: ['DriverGroup', 'Driver'],
    // drivergroupranktabs: ['Driver'],
    // drivertabs: ['Driver'],
    // vehiclegrouptabs: ['VehicleGroup', 'Vehicle'],
    // vehiclegroupranktabs: ['Vehicle'],
    // vehicletabs: ['Vehicle'],
    // ranktabs: ['Vehicle', 'Driver'],
    search: '',
    tabFlag: '', // flag used to filter the headers in computed.
    tableList: [],
    benchmarkItem: null,
    // downloadList: [],  //! too on9, change to computed
    warningList: [],
    headers: [],
    groupheaders: [],
    // camType: [],
  }),
  computed: {
    downloadList: {
      get() {
        return this.tableList.map((item) => {
          return this.headers.reduce((prev, head) => {
            prev[head.text] = item[head.value];
            return prev;
          }, {});
        });
      },
    },
    ...mapState('app', ['camType']),
    camTYpe: {
      get() {
        return this.$store.state.app.camType;
      },
    },
    tabs() {
      let tab = [];
      if (this.query.TargetType === 'company') {
        if (this.tableFlag === 'ScoreRanking') {
          tab = ['Vehicle', 'Driver'];
        } else {
          tab = [
            'Company',
            'Vehicle',
            'VehicleDepartment',
            'VehicleGroup',
            'Driver',
            'DriverDepartment',
            'DriverGroup',
          ];
        }
      } else {
        if (this.query.TargetType === 'vehicle') {
          tab = ['Vehicle'];
        }
        if (this.query.TargetType === 'driver') {
          tab = ['Driver'];
        }
        if (this.query.TargetType === 'vehiclegroup') {
          if (this.tableFlag === 'ScoreRanking') {
            tab = ['Vehicle'];
          } else {
            tab = ['VehicleGroup', 'Vehicle'];
          }
        }
        if (this.query.TargetType === 'vehicledepartment') {
          if (this.tableFlag === 'ScoreRanking') {
            tab = ['Vehicle'];
          } else {
            tab = ['VehicleDepartment', 'Vehicle'];
          }
        }
        if (this.query.TargetType === 'drivergroup') {
          if (this.tableFlag === 'ScoreRanking') {
            tab = ['Driver'];
          } else {
            tab = ['DriverGroup', 'Driver'];
          }
        }
        if (this.query.TargetType === 'driverdepartment') {
          if (this.tableFlag === 'ScoreRanking') {
            tab = ['Driver'];
          } else {
            tab = ['DriverDepartment', 'Driver'];
          }
        }
      }
      return tab;
    },
    cTableList: {
      get() {
        if (this.$store.state.app.show100marks) {
          return this.tableList;
        } else {
          return this.tableList.filter((item) => item.totalScore < 100);
        }
      },
    },
  },
  watch: {
    query(val) {
      setTimeout(() => {
        this.onTabChange();
      }, 500);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.getWarningList();
    },
    '$store.state.app.company'(val) {
      this.getWarningList();
    },
    '$store.state.app.profileViewMode'(val, oldval) {
      // console.log(`${oldval} ${val}`);
      // this.detailColumnsSwitchToggled(val);
      // ! in Profile, watch profileview will reset warningFilter and watch warningFilter below will update view
      // ! so here no need call update view
    },
    '$store.state.app.warningFilter'(_val) {
      this.detailColumnsSwitchToggled(this.$store.state.app.profileViewMode);
    },
  },
  mounted() {
    // this.getWarningList();
  },
  methods: {
    detailColumnsSwitchToggled(on) {
      console.log(`switch: ${on} ${this.headers.length}`);
      this.setHeaders();

      const filterfunc = ({ value, color }) => {
        if (color === undefined || color === '') return true;

        return (
          this.aggregateWarningFilterFunc(value, on) &&
          this.$store.state.app.warningFilter.includes(value.toUpperCase())
        );
      };
      this.headers = this.headers.filter(filterfunc);
      this.groupheaders = this.groupheaders
        .filter((gh) =>
          gh.items ? gh.items.filter(filterfunc).length > 0 : true
        )
        .map((gh) => {
          if (gh.items) gh.colspan = gh.items.filter(filterfunc).length;
          return gh;
        });
    },
    getWarningList() {
      if (this.$store.state.app.warningsMetaInfo) {
        console.log('get warnings meta info from in memory state');
        this.warningList = this.$store.state.app.warningsMetaInfo.items;
        this.detailColumnsSwitchToggled(this.$store.state.app.profileViewMode);
      } else {
        SettingApiService.getWarningTypeSettings(this.$axios)
          .then((response) => {
            if (response) {
              this.warningList = response.items;
              //* move inside toggle detail view
              // this.setHeaders();

              this.detailColumnsSwitchToggled(
                this.$store.state.app.profileViewMode
              );
            }
          })
          .catch({});
      }
    },
    setHeaders() {
      /*
      const warningHeader = [];
      const yellowHeader = [];
      const greenHeader = [];

      this.warningList.forEach((item, i) => {
        switch (item.warningTypeGroup) {
          case 'dangerous':
            warningHeader.push({
              text: this.$t(item.warningType.toLowerCase()),
              value: item.warningType.toLowerCase(),
              color: '#ffa2a2',
            });
            break;
          case 'bad':
            yellowHeader.push({
              text: this.$t(item.warningType.toLowerCase()),
              value: item.warningType.toLowerCase(),
              color: 'yellow',
            });
            break;
          case 'comfort':
            greenHeader.push({
              text: this.$t(item.warningType.toLowerCase()),
              value: item.warningType.toLowerCase(),
              color: '#64ff67',
            });
            break;
          default:
            break;
        }
      });

      if (warningHeader.length > 0) {
        warningHeader[0].num = warningHeader.length;
        warningHeader[0].type = 'w';
      }
      if (yellowHeader.length > 0) {
        yellowHeader[0].num = yellowHeader.length;
        yellowHeader[0].type = 'y';
      }
      if (greenHeader.length > 0) {
        greenHeader[0].num = greenHeader.length;
        greenHeader[0].type = 'g';
      }
      */

      let header = [];
      // * tabList which tab user click
      if (this.tabList) {
        if (this.tableFlag === 'Idling') {
          if (this.tabList.toLowerCase() === this.query.TargetType) {
            header = [{ text: this.$t('accDate'), value: 'accDate' }];
          } else if (!['Company'].includes(this.tabList)) {
            if (this.tabList === 'Vehicle') {
              header = [{ text: this.$t('vin'), value: 'name' }];
            } else if (this.tabList === 'Driver') {
              header = [{ text: this.$t('driverCode'), value: 'name' }];
            } else if (this.tabList.includes('Group')) {
              header = [{ text: this.$t('group'), value: 'name' }];
            } else if (this.tabList.includes('Department')) {
              header = [{ text: this.$t('dept'), value: 'name' }];
            }

            if (!this.tabList.includes('Department')) {
              header.push(
                ...[{ text: this.$t('department'), value: 'department' }]
              );
            }
          }
          header.push(
            ...[
              { text: `${this.$t('drivingHours')} (h)`, value: 'duration' },
              { text: this.$t('idl'), value: 'idl' },
              { text: this.$t('idL_Longest'), value: 'idL_Longest' },
              { text: this.$t('idL_Duration'), value: 'idL_Duration' },
              { text: this.$t('idL_Rate'), value: 'idL_Rate' },
            ]
          );
        } else {
          // * query.TargetType, which type selected in querybox
          if (this.tabList.toLowerCase() === this.query.TargetType) {
            header = [{ text: this.$t('accDate'), value: 'accDate', num: 4 }];
            if (
              ['vehicle', 'driver'].includes(this.query.TargetType) &&
              this.tableFlag === 'ScoreRanking'
            ) {
              header.push({ text: this.$t('TotalRank'), value: 'overallRank' });
            } else {
              header.push({ text: this.$t('totalScore'), value: 'totalScore' });
              header.push({ text: this.$t('grade'), value: 'grade' });
            }
            header.push({
              text: this.$t('distance'),
              value: 'distance',
              sortable: true,
            });
          } else if (
            this.tabList === 'VehicleGroup' ||
            this.tabList === 'DriverGroup'
          ) {
            header = [
              { text: this.$t('group'), value: 'group', num: 5 },
              { text: this.$t('dept'), value: 'dept' },
              { text: this.$t('totalScore'), value: 'totalScore' },
              { text: this.$t('grade'), value: 'grade' },
              { text: this.$t('distance'), value: 'distance' },
            ];
          } else if (this.tabList === 'Vehicle') {
            // * when query is company, and select vehicle tab and ScoreRanking accordion
            if (this.tableFlag === 'ScoreRanking') {
              header = [
                { text: this.$t('vin'), value: 'vin', num: 4 },
                { text: this.$t('dept'), value: 'dept' },
                { text: this.$t('TotalRank'), value: 'overallRank' },
                { text: this.$t('distance'), value: 'distance' },
              ];
            } else {
              header = [
                { text: this.$t('vin'), value: 'vin', num: 5 },
                { text: this.$t('dept'), value: 'dept' },
                { text: this.$t('totalScore'), value: 'totalScore' },
                { text: this.$t('grade'), value: 'grade' },
                { text: this.$t('distance'), value: 'distance' },
              ];
            }
          } else if (this.tabList === 'Driver') {
            if (this.tableFlag === 'ScoreRanking') {
              header = [
                { text: this.$t('driverCode'), value: 'name', num: 4 },
                { text: this.$t('dept'), value: 'dept' },
                { text: this.$t('TotalRank'), value: 'overallRank' },
                { text: this.$t('distance'), value: 'distance' },
              ];
            } else {
              header = [
                { text: this.$t('driverCode'), value: 'name', num: 5 },
                { text: this.$t('dept'), value: 'dept' },
                { text: this.$t('totalScore'), value: 'totalScore' },
                { text: this.$t('grade'), value: 'grade' },
                { text: this.$t('distance'), value: 'distance' },
              ];
            }
          } else if (this.tableFlag === 'ScoreRanking') {
            header = [
              { text: this.$t('name'), value: 'name', num: 4 },
              { text: this.$t('dept'), value: 'dept' },
              { text: this.$t('TotalRank'), value: 'overallRank' },
              { text: this.$t('distance'), value: 'distance' },
            ];
          } else {
            header = [
              { text: this.$t('dept'), value: 'name', num: 4 },
              // { text: this.$t('dept'), value: 'dept' },
              { text: this.$t('totalScore'), value: 'totalScore' },
              { text: this.$t('grade'), value: 'grade' },
              { text: this.$t('distance'), value: 'distance' },
            ];
          }

          header.push({
            text: `${this.$t('drivingHours')} (h)`,
            value: 'duration',
          });
        }
      }

      if (this.tableFlag !== 'Idling') {
        this.groupheaders = [];
        this.groupheaders.push({
          color: '',
          colspan: header.length,
          type: '-',
        });

        const headerGroupsDict = {
          dangerous: { headers: [], color: '#ffa2a2', type: 'w' },
          bad: { headers: [], color: 'yellow', type: 'y' },
          comfort: { headers: [], color: '#64ff67', type: 'g' },
        };

        for (const item of this.warningList) {
          if (!(item.warningTypeGroup in headerGroupsDict)) {
            continue;
          }
          const headerGroup = headerGroupsDict[item.warningTypeGroup];
          headerGroup.headers.push({
            text: this.$t(item.warningType),
            value: item.warningType.toLowerCase(),
            color: headerGroup.color,
          });
        }

        for (const headerGroup of Object.values(headerGroupsDict)) {
          if (headerGroup.headers.length > 0) {
            headerGroup.headers[0].num = headerGroup.headers.length;
            headerGroup.headers[0].type = headerGroup.type;
            this.groupheaders.push({
              color: headerGroup.color,
              colspan: headerGroup.headers.length,
              type: headerGroup.type,
              items: headerGroup.headers,
            });
          }
        }
      }
      /*
      if (warningHeader.length > 0) {
        this.groupheaders.push({
          color: '#ffa2a2',
          colspan: warningHeader.length,
          type: 'w',
          items: warningHeader,
        });
      }
      if (yellowHeader.length > 0) {
        this.groupheaders.push({
          color: 'yellow',
          colspan: yellowHeader.length,
          type: 'y',
          items: yellowHeader,
        });
      }
      if (greenHeader.length > 0) {
        this.groupheaders.push({
          color: '#64ff67',
          colspan: greenHeader.length,
          type: 'g',
          items: greenHeader,
        });
      }
      */
      this.headers = [
        ...header,
        ...Object.values(this.groupheaders)
          .map((gh) => gh.items || [])
          .flat(),
      ];
      /*
      this.headers = [
        ...header,
        ...warningHeader,
        ...yellowHeader,
        ...greenHeader,
      ];
    */
    },
    reload(val) {
      if (val === 'ActualWarningCount') {
        this.getProfileWarningCount(this.query);
      }
    },
    customSort(items, index, isDesc) {
      // Sort for distance
      if (index[0] === 'distance') {
        items.sort((a, b) => {
          // Clear all commas if they have
          const Ele1 = parseFloat(a.distance.replace(/,/g, ''));
          const Ele2 = parseFloat(b.distance.replace(/,/g, ''));
          if (isDesc[0]) {
            return Ele2 - Ele1;
          } else {
            return Ele1 - Ele2;
          }
        });
      }
      // Sort for everything else
      else {
        items.sort((a, b) => {
          // if a[index[0]] and b[index[0]] are values
          if (!isNaN(a[index[0]]) && !isNaN(b[index[0]])) {
            if (isDesc[0]) {
              return b[index[0]] - a[index[0]];
            } else return a[index[0]] - b[index[0]];
          }
          // else sort by names
          else {
            // eslint-disable-next-line
            if (isDesc[0]) {
              if (a[index[0]] < b[index[0]]) return 1;
              if (a[index[0]] > b[index[0]]) return -1;
              return 0;
            } else {
              if (a[index[0]] < b[index[0]]) return -1;
              if (a[index[0]] > b[index[0]]) return 1;
              return 0;
            }
          }
        });
      }
      return items;
    },
    handleClick(value) {
      if (
        [
          'company',
          'drivergroup',
          'driverdepartment',
          'vehiclegroup',
          'vehicledepartment',
        ].includes(this.query.TargetType)
      ) {
        let tempid = '';
        if (
          this.query.OutputType === 'Vehicle' ||
          this.query.OutputType === 'Driver'
        ) {
          tempid = value.name + ' [' + value.dept + ']';
        } else {
          tempid = value.name;
        }
        const newQuery = {
          TargetType: this.query.OutputType,
          TargetId: tempid,
          Date: this.query.Date,
          ProfileType: this.query.ProfileType,
          OutputType: this.query.OutputType,
          Distance: this.query.Distance,
        };
        localStorage.setItem('clickedQuery', JSON.stringify(newQuery));
        window.open(window.location.href, 'newWindow');
      }
    },
    // Distance will appear in a form of X,XXX.X
    // Score will appear in a form of XXX.XX
    createObj(Obj) {
      const obj = {
        accDate: Obj.accDate.slice(0, 10),
        group: Obj.name,
        vin: Obj.name,
        name: Obj.name,
        dept: Obj.department,
        overallRank: Obj.overallRank,
        grade: Obj.grade,
        distance: Obj.distance.toFixed(1),
        duration: Obj.duration,
      };

      Object.keys(Obj.results).forEach((warningKey) => {
        obj[warningKey.toLowerCase()] = Obj.results[warningKey];
      });

      if (Obj.totalScore)
        return { totalScore: Obj.totalScore.toFixed(2), ...obj };
      else return obj;
    },
    // numberWithCommas(num) {
    //   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // },
    getProfileIdling(query) {
      this.tableList = [];
      ApiService.getProfileIdling(this.$axios, query)
        .then((response) => {
          if (response.items === null) return;

          /* eslint-disable camelcase */
          this.tableList = response.items.map(
            ({
              accDate,
              name,
              department,
              duration,
              idl,
              idL_Duration,
              idL_Rate,
              idL_Longest,
            }) => ({
              accDate: accDate.slice(0, 10),
              name,
              department,
              dept: department,
              duration,
              idl,
              idL_Duration,
              idL_Rate,
              idL_Longest,
            })
          );
          if (response.benchmarkItem)
            this.benchmarkItem = {
              ...response.benchmarkItem,
            };
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Idling ' + JSON.stringify(error.response)
          );
        })
        .finally(() => {
          const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
          console.debug(`${this.$refs[refkey]} dont show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(false);
        });
    },
    getRateOfChange(startSpeed, endSpeed, duration) {
      return (startSpeed - endSpeed) / duration;
    },
    getProfileWarningCount(query) {
      this.tableList = [];
      ApiService.getProfileWarningCount(this.$axios, query)
        .then((response) => {
          if (response.items === null) return;

          this.tableList = response.items.map((p) => this.createObj(p));
          if (response.benchmarkItem)
            this.benchmarkItem = this.createObj(response.benchmarkItem);
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Warning Count ' + JSON.stringify(error.response)
          );
        })
        .finally(() => {
          const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
          console.debug(`${this.$refs[refkey]} dont show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(false);
        });
    },
    getProfileWarningCountper100km(query) {
      this.tableList = [];
      ApiService.getProfileWarningCountper100km(this.$axios, query)
        .then((response) => {
          if (response.items === null) return;

          this.tableList = response.items.map((p) => this.createObj(p));
          if (response.benchmarkItem)
            this.benchmarkItem = this.createObj(response.benchmarkItem);
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Warning Count per 100 km ' +
              JSON.stringify(error.response)
          );
        })
        .finally(() => {
          const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
          console.debug(`${this.$refs[refkey]} dont show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(false);
        });
    },
    getProfileScore(query) {
      this.tableList = [];
      ApiService.getProfileScore(this.$axios, query)
        .then((response) => {
          if (response.items === null) return;

          this.tableList = response.items.map((p) => this.createObj(p));

          if (response.benchmarkItem)
            this.benchmarkItem = this.createObj(response.benchmarkItem);
        })
        .finally(() => {
          const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
          console.debug(`${this.$refs[refkey]} dont show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(false);
        });
    },
    getProfileRanking(query) {
      this.tableList = [];
      ApiService.getProfileRanking(this.$axios, query)
        .then((response) => {
          if (response.items === null) return;

          this.tableList = response.items.map((p) => this.createObj(p));
        })
        .catch((error) => {
          console.log(
            error.response
            // 'ERROR: Profile Ranking ' + JSON.stringify(error.response)
          );
        })
        .finally(() => {
          const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
          console.debug(`${this.$refs[refkey]} dont show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(false);
        });
    },
    onTabChange() {
      if (this.tabList) {
        this.query.OutputType = this.tabList;
      } else {
        return;
      }

      //* tabList is equal to selectedTab
      //* on first time expansion panel expanded, refs is undefined
      //* show loading default to true in tablePanel
      console.debug('onTabChange');
      console.debug(`tab ${this.tabList}`);
      const refkey = `profileTablePanel-${this.tableFlag}-${this.tabList}`;
      if (this.$refs[refkey] !== null && this.$refs[refkey] !== undefined) {
        if (this.$refs[refkey].length > 0) {
          console.debug(`${this.$refs[refkey][0]} show loading`);
          this.$refs[refkey][0].setDataTableShowLoading(true);
        }
      } else {
        console.debug(`${refkey} is ${this.$refs[refkey]}`);
      }

      if (this.tableFlag === 'Idling') {
        this.getProfileIdling(this.query);
      } else if (this.tableFlag === 'AvgWarningCount') {
        this.getProfileWarningCountper100km(this.query);
      } else if (this.tableFlag === 'SafetyScore') {
        this.getProfileScore(this.query);
      } else if (this.tableFlag === 'ScoreRanking') {
        this.getProfileRanking(this.query);
      } else {
        this.getProfileWarningCount(this.query);
      }
      this.getWarningList();
    },
    // refresh() {
    //   this.onTabChange();
    // },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadFile(this.$axios, formData).then((response) => {
        if (!response.success) {
          this.msg = response.message;
          this.resColor = 'red';
        } else {
          this.msg = this.$t('UploadSuccess');
          this.resColor = 'green';
        }
        this.showMsg = true;
      });
      this.$refs.fileInputImport.value = '';
    },
    csvName() {
      if (this.apiFlag === 'a') {
        if (this.tabList === 'Company') {
          return 'ActualWarningCountCompany';
        } else if (this.tabList === 'VehicleGroup') {
          return 'ActualWarningCountVehicleGroup';
        } else if (this.tabList === 'Vehicle') {
          return 'ActualWarningCountVehicle';
        } else if (this.tabList === 'Driver') {
          return 'ActualWarningCountDriver';
        } else if (this.tabList === 'DriverGroup') {
          return 'ActualWarningCountDriverGroup';
        }
      } else if (this.apiFlag === 'w') {
        if (this.tabList === 'Company') {
          return 'AvgWarningCountCompany';
        } else if (this.tabList === 'VehicleGroup') {
          return 'AvgWarningCountVehicleGroup';
        } else if (this.tabList === 'Vehicle') {
          return 'AvgWarningCountVehicle';
        } else if (this.tabList === 'Driver') {
          return 'AvgWarningCountDriver';
        } else if (this.tabList === 'DriverGroup') {
          return 'AvgWarningCountDriverGroup';
        }
      } else if (this.apiFlag === 's') {
        if (this.tabList === 'Company') {
          return 'SafetyScoreCompany';
        } else if (this.tabList === 'VehicleGroup') {
          return 'SafetyScoreVehicleGroup';
        } else if (this.tabList === 'Vehicle') {
          return 'SafetyScoreVehicle';
        } else if (this.tabList === 'Driver') {
          return 'SafetyScoreDriverGroup';
        } else if (this.tabList === 'DriverGroup') {
          return 'SafetyScoreDriver';
        }
      } else if (this.apiFlag === 'r') {
        if (this.tabList === 'Vehicle') {
          return 'ScoreRankingVehicle';
        } else if (this.tabList === 'Driver') {
          return 'ScoreRankingDriver';
        }
      }
    },
  },
};
