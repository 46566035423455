
/* eslint-disable @typescript-eslint/no-unused-vars */

import { mapMutations } from 'vuex';
import ApiService from '@/services/api-service.js';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
  },
  props: {
    lastquery: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDisabled: false,
      profileType: [],
      QueryString: '',
      km: 20,
      queryType: 'month',
      scoreGroups: [],
      queryTypeGroup: [],
      mode: 'month',
      formatDate: 'YYYY-MM',
      time: new Date(new Date().setDate(new Date().getDate() - 1)),
      getOptions: [],
      optionsList: [],
      tabFlag: true, // Is this webpage opened by a previous M3 window? Only stay true for once.
      normalizer(node) {
        if (node.children == null || node.children.length === 0) {
          delete node.children;
        }
        return {
          id: node.name,
          label: node.name,
          children: node.children,
        };
      },
    };
  },
  computed: {
    langForDatepicker: {
      get() {
        return this.$i18n.locale === 'zh-Hant' ? 'zh-cn' : this.$i18n.locale;
      },
    },

    profileViewMode: {
      get() {
        this.get_ProfileViewMode();
        return this.$store.state.app.profileViewMode;
      },
      set(val) {
        this.set_ProfileViewMode(val);
      },
    },

    show100marks: {
      get() {
        this.get_show100marks();
        return this.$store.state.app.show100marks;
      },
      set(val) {
        this.set_show100marks(val);
      },
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setQueryBox();
      this.sendForm();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.setOptions();
      // setTimeout(() => {
      //   this.sendForm();
      // }, 500);
    },
    '$store.state.app.userRole'(val) {
      this.setQueryBox();
    },
    queryType(val) {
      if (val === 'day') {
        this.mode = 'date';
        this.formatDate = 'YYYY-MM-DD';
      } else if (val === 'week') {
        this.mode = 'week';
        this.formatDate = 'YYYY ww';
      } else if (val === 'month') {
        this.mode = 'month';
        this.formatDate = 'YYYY-MM';
      } else if (val === 'year') {
        this.mode = 'year';
        this.formatDate = 'YYYY';
      }
      // if (window.opener == null) {
      this.sendForm();
      // }
    },
    time(val) {
      if (val) {
        // if (window.opener == null) {
        this.sendForm();
        // }
      }
    },
    km(val) {
      if (val) {
        // if (window.opener == null) {
        this.sendForm();
        // }
      }
    },
    profileType(val) {
      this.optionsList = [];
      if (
        val.value === 'driverdepartment' ||
        val.value === 'vehicledepartment'
      ) {
        this.getOptions = null;
      } else {
        this.getOptions = [];
      }
      if (this.lastquery.TargetType !== 'company' && val.value === 'company')
        if (window.opener == null) {
          this.sendForm();
        }
    },
    getOptions(val) {
      if (val) {
        if (
          this.profileType.value === 'driverdepartment' ||
          this.profileType.value === 'vehicledepartment'
        ) {
          // if (window.opener == null && !this.tabFlag) {
          this.sendForm();
          // }
        } else if (val.length > 0) {
          // if (window.opener == null && !this.tabFlag) {
          this.sendForm();
          // }
        }
      }
    },
  },
  mounted() {
    if (window.opener == null) {
      this.setOptions();

      // setTimeout(() => {
      //   this.sendForm();
      // }, 500);
    } else {
      this.isDisabled = true;
      this.sendForm();
    }
  },
  methods: {
    ...mapMutations('app', [
      'get_ProfileViewMode',
      'set_ProfileViewMode',
      'get_show100marks',
      'set_show100marks',
    ]),

    getLang(item) {
      return this.$t(item.text);
    },
    onChange() {
      if (this.profileType.value !== 'company') {
        console.log(this.profileType.value);
        this.getOptionList(this.profileType);
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    setQueryBox() {
      const val = this.$store.state.app.userRole;
      if (val === 'HQ') {
        this.queryTypeGroup = [
          { text: this.$t('day'), value: 'day' },
          { text: this.$t('week'), value: 'week' },
          { text: this.$t('month'), value: 'month' },
          { text: this.$t('year'), value: 'year' },
        ];
      } else {
        this.queryTypeGroup = [
          { text: this.$t('week'), value: 'week' },
          { text: this.$t('month'), value: 'month' },
          { text: this.$t('year'), value: 'year' },
        ];
      }
    },
    setOptions() {
      this.setQueryBox();

      this.scoreGroups = [];
      this.scoreGroups.push({
        text: 'Company',
        value: 'company',
        url: '',
        active: true,
        disabled: false,
      });

      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Driver Group':
              case 'Driver Department':
              case 'Vehicle':
              case 'Vehicle Group':
              case 'Vehicle Department':
                this.scoreGroups.push({
                  text: item.name,
                  value: item.value,
                  url: item.url,
                  active: true,
                  disabled: false,
                });
                break;
              default:
                break;
            }
          });
          this.profileType = this.scoreGroups[0];
          if (window.opener != null) {
            try {
              const clickedQuery = JSON.parse(localStorage.clickedQuery);
              this.scoreGroups.forEach((item) => {
                if (item.value === clickedQuery.TargetType.toLowerCase()) {
                  this.profileType = item;
                  this.getOptionList(this.profileType);
                }
              });
            } catch (e) {
              console.log(e);
            }
          } else {
            this.sendForm();
          }
        }
      });
    },
    getOptionList(profileType) {
      const url = profileType.url;
      const params = {
        IsSortAscending: true,
      };
      if (
        [
          'driver',
          'driverdepartment',
          'drivergroup',
          'vehiclegroup',
          'vehicledepartment',
        ].includes(profileType.value)
      ) {
        params.SortBy = 'name';
      } else if (profileType.value === 'vehicle') {
        params.SortBy = 'Plate';
      }
      this.optionsList = [];
      ApiService.getWarningDataOptionList(this.$axios, url, params).then(
        (response) => {
          if (response.items) {
            response.items.forEach((item) => {
              if (
                this.profileType.value === 'driverdepartment' ||
                this.profileType.value === 'vehicledepartment'
              ) {
                this.optionsList.push({
                  id: item.name ? item.name : item,
                  name: item.name ? item.name : item,
                  children: item.children ? item.children : null,
                });
              } else {
                this.optionsList.push({
                  id: item,
                  name: item,
                });
              }
            });
            // If this is opened by parent M3 website, get query from localStorage.
            if (window.opener != null) {
              try {
                const clickedQuery = JSON.parse(localStorage.clickedQuery);
                this.getOptions = clickedQuery.TargetId;
                this.queryType = clickedQuery.ProfileType;
                this.time = new Date(clickedQuery.Date);
              } catch (e) {
                console.log(e);
              }
            }
            this.tabFlag = false;
          }
        }
      );
    },
    disableDate(date) {
      // return date > new Date();
      return this.warningDatePickerDisableDate(date);
    },
    sendForm() {
      const form = {
        targetType: this.profileType.value,
        targetId: this.getOptions,
        queryType: this.queryType,
        km: this.km,
        format: this.formatDate,
        time: this.time,
      };
      // console.log(form);
      this.$emit('qryform', form);
    },
  },
};
